import { ratingOptions } from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import {
  SectionType,
  SkillCardInterface,
  ValueBasedCardInterface,
} from '@src/interfaces/performance'
import { Flex, Icon, Text, VStack } from '@revolut/ui-kit'
import React from 'react'

export const getSkillsRatingOptions = (cardInd: number, cards?: SkillCardInterface[]) => {
  return ratingOptions.map((option, optionInd) => {
    const skipUntilInd = 2
    const prevOption =
      optionInd === skipUntilInd ? undefined : ratingOptions[optionInd - 1]

    // in the normalized card all sections above selected are cleaned up
    const notNormalizedCardSections = cards?.[cardInd]?.sections
    const cardSection = notNormalizedCardSections?.find(section => {
      return section.type === SectionType.CriteriasAssessment
        ? section.level_key === option.key
        : false
    })
    const sectionItems =
      cardSection?.type === SectionType.CriteriasAssessment ? cardSection.items : null

    return {
      ...option,
      tooltip: optionInd >= skipUntilInd && (
        <VStack space="s-8">
          {prevOption ? (
            <Text>
              All criteria for <Text fontWeight={700}>{prevOption.text}</Text> rating and{' '}
              the following <Text fontWeight={700}>{option.text}</Text> criteria are met:
            </Text>
          ) : (
            <Text>
              All criteria for the following <Text fontWeight={700}>{option.text}</Text>{' '}
              criteria are met:
            </Text>
          )}
          {sectionItems?.map((item, itemInd) => (
            <Flex key={itemInd} gap="s-8">
              <Icon name="CheckSuccess" color="success" size={16} mt="s-4" />
              <Text>{item.text}</Text>
            </Flex>
          ))}
        </VStack>
      ),
    }
  })
}

export const getValuesRatingOptions = (
  options: { key: string; text: string }[],
  cardInd: number,
  cards?: ValueBasedCardInterface[],
) => {
  return options.map((option, optionInd) => {
    const skipUntilInd = 1

    const sections = cards?.[cardInd]?.sections
    const sectionItems = sections?.map(section => {
      return {
        title: section.text,
        description: section.description
          .split(/\n/)
          .filter(item => item.replace(' ', '').length)[optionInd - 1],
      }
    })

    return {
      ...option,
      tooltip: optionInd >= skipUntilInd && (
        <VStack space="s-8">
          {sectionItems?.map((item, itemInd) => (
            <Flex key={itemInd} gap="s-8">
              <Icon name="CheckSuccess" color="success" size={16} mt="s-4" />
              <Text>
                <Text fontWeight={700}>{item.title}</Text> - {item.description}
              </Text>
            </Flex>
          ))}
        </VStack>
      ),
    }
  })
}
