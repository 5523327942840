import React from 'react'
import {
  ReviewScorecardInterface,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Box, TableWidget } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { KpiInterface } from '@src/interfaces/kpis'
import { TableNames } from '@src/constants/table'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { Statuses } from '@src/interfaces'
import { useGetEmployeeWithManagerFilter } from '@src/api/employees'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useTable } from '@components/Table/hooks'
import { kpisRequests } from '@src/api/kpis'
import { getKpisRow } from '@src/pages/Forms/EmployeePerformanceLayout/components/KPIsCardTable'
import { GradesMapInterface } from '@src/utils/grades'

interface DeliverablesCardInterface {
  employee: EmployeeInterface
  gradesMap: GradesMapInterface
}

const DeliverablesCardTableContent = ({
  employee,
  gradesMap,
}: DeliverablesCardInterface) => {
  const { values } = useLapeContext<ReviewScorecardInterface | ReviewSummaryInterface>()
  const cycleId = 'reviews' in values ? values.reviews[0].cycle?.id : values.cycle?.id

  const table = useTable<KpiInterface>(kpisRequests, [
    {
      columnName: 'status',
      filters: [{ name: Statuses.active, id: Statuses.active }],
    },
    {
      columnName: 'cycle__id',
      filters: cycleId
        ? [{ name: String(cycleId), id: cycleId }]
        : [{ name: '0', id: 0 }],
      nonResettable: true,
    },
    {
      filters: [{ name: String(employee?.team.id), id: Number(employee?.team.id) }],
      columnName: 'team__id',
      nonResettable: true,
      nonInheritable: true,
    },
  ])

  return (
    <Box px="s-12">
      <TableWidget style={{ padding: 0 }}>
        <TableWidget.Table>
          <AdjustableTable<KpiInterface>
            name={TableNames.EmployeePerformanceKPI}
            dataType="KPI"
            row={getKpisRow(gradesMap)}
            {...table}
            noDataMessage="No KPI defined"
            onRowClick={onKPITableRowClick}
            noReset
            hideCountAndButtonSection
          />
        </TableWidget.Table>
      </TableWidget>
    </Box>
  )
}

export const DeliverablesCardTable = connect(
  ({ gradesMap }: { gradesMap: GradesMapInterface }) => {
    const { values } = useLapeContext<ReviewScorecardInterface>()
    const { data: employee } = useGetEmployeeWithManagerFilter(
      values.reviewed_employee.id,
      'line_manager__id',
    )

    if (!employee) {
      return null
    }

    return <DeliverablesCardTableContent employee={employee} gradesMap={gradesMap} />
  },
)
