import React from 'react'
import { FinalGrade, getGradeColor } from '@src/interfaces/performance'
import {
  TextProps,
  Text,
  Flex,
  Badge,
  Token,
  useTooltip,
  Tooltip,
  VStack,
} from '@revolut/ui-kit'
import { GradesMapInterface } from '@src/utils/grades'
import { RecommendedGradesInterface } from '@src/pages/Forms/EmployeePerformanceLayout/utils'

interface Props extends TextProps {
  value: FinalGrade
  gradesMap: GradesMapInterface
  recommendedGrades: RecommendedGradesInterface
}

export const RecommendationGradeRevamped = ({
  value,
  gradesMap,
  recommendedGrades,
  ...rest
}: Props) => {
  const color = getGradeColor(value)
  const tooltip = useTooltip()

  const goalsGrade =
    recommendedGrades.grades?.kpiGrade || recommendedGrades.grades?.deliverablesGrade
  const skillsGrade = recommendedGrades.grades?.skillsGrade
  const valuesGrade =
    recommendedGrades.grades?.cultureValuesGrade ||
    recommendedGrades.grades?.cultureSkillsGrade

  return (
    <Flex gap="s-8">
      <Text use="div" color={color} {...rest}>
        {gradesMap[value]}
      </Text>
      {value === recommendedGrades.recommendedGrade && (
        <>
          <Badge
            size={20}
            bg={Token.color.blue_10}
            color={Token.color.blue}
            px="s-8"
            {...tooltip.getAnchorProps()}
          >
            Recommended
          </Badge>
          <Tooltip {...tooltip.getTargetProps()} placement="right-end" width={300}>
            <VStack p="s-12">
              <Text variant="primary" mb="s-8">
                Why is this grade recommended?
              </Text>
              {goalsGrade && (
                <Flex justifyContent="space-between">
                  <Text color={Token.color.greyTone50}>50% Goals</Text>
                  <Text color={getGradeColor(goalsGrade)}>{gradesMap[goalsGrade]}</Text>
                </Flex>
              )}
              {skillsGrade && (
                <Flex justifyContent="space-between">
                  <Text color={Token.color.greyTone50}>25% Skills</Text>
                  <Text color={getGradeColor(skillsGrade)}>{gradesMap[skillsGrade]}</Text>
                </Flex>
              )}
              {valuesGrade && (
                <Flex justifyContent="space-between">
                  <Text color={Token.color.greyTone50}>25% Values</Text>
                  <Text color={getGradeColor(valuesGrade)}>{gradesMap[valuesGrade]}</Text>
                </Flex>
              )}
            </VStack>
          </Tooltip>
        </>
      )}
    </Flex>
  )
}
