import React, { RefObject, useContext } from 'react'
import styled from 'styled-components'
import {
  CriteriaAssessmentValues,
  PerformanceKPISection,
  PerformanceReviewTypes,
  PreviousDeliverableJustification,
  PreviousReviewInterface,
  PreviousSkillJustification,
  ReviewInterface,
  SkillCardInterface,
  SummarySkillCardInterface,
} from '@src/interfaces/performance'
import ExpandableText from '@components/ExpandableText/ExpandableText'
import LinkJustification from '@src/pages/Forms/EmployeePerformance/LinkJustification'
import PreviousDeliverablesJustifications from '@components/ScorecardGeneral/PreviousDeliverablesJustifications'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { PreviousBlock, PreviousCycleCoverCss } from '@components/ScorecardGeneral/Common'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { relationToString } from '@src/features/Scorecard/constants'
import { Text, Flex } from '@revolut/ui-kit'
import { PREVIOUS_CYCLE_WIDTH } from '@src/constants/performance'
import { ShowPreviousValuesContext } from '@components/ScorecardGeneral/utils'
import PreviewNotes from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/PreviewNotes'
import { recommendedRatingOptions } from '@src/pages/Forms/EmployeePerformance/KPI'

const Justification = styled.div<{ count?: number }>`
  margin-top: 16px;
  ${props => PreviousCycleCoverCss(props.count || 0)}
`

const PreviousJustifications = styled.div`
  ${PreviousBlock};
  top: 14px;
  button:nth-child(2) {
    margin-left: 1px;
  }
`

type Props = {
  reviews: ReviewInterface[]
  justificationRef?: RefObject<HTMLDivElement>
  card: SummarySkillCardInterface | SkillCardInterface | PerformanceKPISection
  viewMode?: boolean
  type?: PerformanceReviewTypes
  cardIndex: number
  disabled?: boolean
  previousReviews?: PreviousReviewInterface[]
}

const CardJustification = ({
  type,
  viewMode,
  card,
  justificationRef,
  reviews,
  cardIndex,
  disabled,
  previousReviews,
}: Props) => {
  const showPreviousRatings = useContext(ShowPreviousValuesContext)
  const user = useSelector(selectUser)

  const getSkillsViewJustification = (list: CriteriaAssessmentValues[]) => {
    return list
      .filter(review => review.value)
      .map(review => {
        const reviewer = review.review?.reviewer
        const relation =
          reviews.find(rev => rev.id === review?.review?.id)?.reviewer_relation || ''
        const name = user.id === reviewer?.id ? 'You' : reviewer.full_name
        return {
          title: name,
          additionalInfo: relation ? relationToString(relation, true) : undefined,
          items: [
            {
              text: review.value!,
            },
          ],
        }
      })
  }

  switch (type) {
    case PerformanceReviewTypes.kpi: {
      const getRatingLabel = (rating: string) =>
        recommendedRatingOptions.find(opt => opt.value.id === rating)?.label

      const jusitifcationList = (card as PerformanceKPISection).comments || []
      const recommendedRatingsList =
        (card as PerformanceKPISection).recommended_ratings?.map(rating => ({
          ...rating,
          value: rating.value
            ? `Suggested delivery rating: ${getRatingLabel(rating.value) || rating.value}`
            : undefined,
        })) || []

      const justificationListView = getSkillsViewJustification(
        jusitifcationList as CriteriaAssessmentValues[],
      )
      const recommendedRatingsListView = getSkillsViewJustification(
        recommendedRatingsList as CriteriaAssessmentValues[],
      )

      const combinedView = [...recommendedRatingsListView]

      justificationListView.forEach(justification => {
        const sameReviewer = combinedView.find(i => i.title === justification.title)
        if (sameReviewer && justification.items.length) {
          sameReviewer.items.push(...justification.items)
        } else if (!sameReviewer) {
          combinedView.push(justification)
        }
      })

      return (
        <Justification>
          <ExpandableText list={combinedView} />
        </Justification>
      )
    }
    case PerformanceReviewTypes.interviewScorecard:
    case PerformanceReviewTypes.cultureFit:
    case PerformanceReviewTypes.managerSkills:
    case PerformanceReviewTypes.skills: {
      if (viewMode) {
        if (type === PerformanceReviewTypes.interviewScorecard) {
          return <PreviewNotes text={(card as SkillCardInterface).justification} />
        }

        const jusitifcationList = (card as SummarySkillCardInterface).justification || []
        return (
          <Justification>
            <ExpandableText
              list={getSkillsViewJustification(
                jusitifcationList as CriteriaAssessmentValues[],
              )}
            />
          </Justification>
        )
      }

      const previousJustifications = (card as SkillCardInterface)
        .previous_justifications as PreviousSkillJustification[]

      let name = null

      switch (type) {
        case PerformanceReviewTypes.cultureFit:
          name = `review_data.culture_skills.cards.${cardIndex}.justification`
          break

        case PerformanceReviewTypes.skills:
          name = `review_data.functional_skills.cards.${cardIndex}.justification`
          break

        case PerformanceReviewTypes.managerSkills:
          name = `review_data.manager_skills.cards.${cardIndex}.justification`
          break

        case PerformanceReviewTypes.interviewScorecard:
          name = `scorecard.sections.${cardIndex}.notes`
          break
      }

      return (
        <Justification
          ref={justificationRef}
          count={showPreviousRatings ? previousJustifications?.length : undefined}
        >
          <LapeNewTextArea
            disabled={disabled}
            name={name}
            label="Justification / evidence or specific examples"
            hideOptional
          />
          {previousJustifications && showPreviousRatings && (
            <PreviousJustifications>
              {previousJustifications.map(item =>
                item.value ? (
                  <Flex width={PREVIOUS_CYCLE_WIDTH} justifyContent="center">
                    <PreviousDeliverablesJustifications
                      justifications={[item]}
                      previousReviews={previousReviews}
                      type={type}
                      title="View"
                      variant="text"
                    />
                  </Flex>
                ) : (
                  <Text
                    use="div"
                    width={PREVIOUS_CYCLE_WIDTH}
                    lineHeight="36px"
                    color="grey-tone-50"
                    textAlign="center"
                  >
                    –
                  </Text>
                ),
              )}
            </PreviousJustifications>
          )}
        </Justification>
      )
    }

    case PerformanceReviewTypes.deliverables: {
      if (viewMode) {
        const jusitifcationList = (card as SummarySkillCardInterface).justifications || []

        return (
          <Justification ref={justificationRef}>
            <ExpandableText
              list={jusitifcationList.map(review => {
                const reviewer = review.review?.reviewer
                const relation =
                  reviews.find(rev => rev.id === review?.review?.id)?.reviewer_relation ||
                  ''
                const name = user.id === reviewer?.id ? 'You' : reviewer.full_name
                return {
                  title: name,
                  additionalInfo: relation ? relationToString(relation, true) : undefined,
                  items: review.value.map((item, id) => {
                    return {
                      index: id,
                      text: item.comment,
                      link: item.reference_url
                        ? {
                            title: `Link ${id + 1}`,
                            url: item.reference_url,
                          }
                        : undefined,
                    }
                  }),
                }
              })}
            />
          </Justification>
        )
      }

      return (
        <Justification>
          <LinkJustification
            name={`review_data.deliverables.cards.${cardIndex}.justifications`}
            previousReviews={
              <PreviousDeliverablesJustifications
                justifications={
                  (card as SkillCardInterface)
                    .previous_justifications as PreviousDeliverableJustification[]
                }
                previousReviews={previousReviews}
                type={type}
                title="View previous quarters justifications"
              />
            }
          />
        </Justification>
      )
    }
    default:
      return null
  }
}

export default CardJustification
